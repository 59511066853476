import React, { useEffect, useState } from "react"
import "../../static/css/styles.css"
import "../../static/css/tailwind.min.css"
import "../../static/css/flaticon.css"
import "../../static/css/fontawesome.min.css"
import "../../static/css/boxicons.min.css"
import "antd/dist/antd.css"
import { Helmet } from "react-helmet"
import Footer from "../components/footer"

export default function Layout({ children, showFooter }) {

  const [month, setMonth] = useState("")
  const [day, setDay] = useState(0)
  const [year, setYear] = useState(0)
  const [showDisclamer, setShowDisclamer] = useState(undefined)

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  useEffect(() => {

    const url = window.location.href
    if (url.includes("checkout")) {
      setShowDisclamer(undefined)
    }
    if (url.includes("tiktok")) {
      setShowDisclamer(1)
    }

    const dateNow = new Date()
    setMonth(capitalizeFirstLetter(dateNow.toLocaleString("en-US", { month: "long" })))
    setDay(dateNow.getDay())
    setYear(dateNow.getFullYear())

    const documentReferrer = document.cookie.replace(
      /(?:(?:^|.*;\s*)document_referrer\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    )
    if (document.referrer && !documentReferrer) {
      document.cookie = `document_referrer=${document.referrer}`
    }
    // ININITALIZE GOOGLE OPTIMIZE EXPERIMENT ON 'optimize.activate'
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: "optimize.activate" })
  }, [])

  return (
    <div id="layout">
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/img/favicon/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/img/favicon/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/img/favicon/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/img/favicon/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/img/favicon/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/img/favicon/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/img/favicon/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/img/favicon/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/favicon/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/img/favicon/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/img/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/img/favicon/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/img/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/img/favicon/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="/img/favicon/ms-icon-144x144.png"
        />
        <meta name="theme-color" content="#ffffff" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        />
        <script src="https://js.stripe.com/v3/"></script>
      </Helmet>
      {/*{showDisclamer && (*/}
      {/*  <div className="disclamer-services">{month} {day}, {year} - All our services working fine. Service 100% risk*/}
      {/*    free.</div>*/}
      {/*)}*/}
      {children}
      {showFooter !== false && <Footer />}
    </div>
  )
}
