import React from "react"
import { Link } from "gatsby"
import CardsIcons from "../../static/img/weaccept.png"
import { useLocation } from "@reach/router"

const Footer = () => {
  const location = useLocation()

  return (
    <div className="bg-gray border-t-2 border-gray-200">
      <div className="container mx-auto flex justify-between">
        <div className="p-5">
          <div
            className={`font-bold color-home color-${
              location.pathname.split("/")[1]
            } text-lg mb-4 pb-3 border-b-2 lg:pr-16 `}
          >
            Products
          </div>
          <div className="mb-1">
            <Link
              to="/"
              className="text-gray-400 hover:text-gray-800"
            >
              Instagram
            </Link>
          </div>
           <div className="mb-1">
            <Link
              to="/"
              className="text-gray-400 hover:text-gray-800"
            >
              YouTube
            </Link>
          </div>
          <div className="mb-1">
            <Link
              to="/"
              className="text-gray-400 hover:text-gray-800"
            >
              SoundCloud
            </Link>
          </div>
          <div className="mb-1">
            <Link
              to="/"
              className="text-gray-400 hover:text-gray-800"
            >
              TikTok
            </Link>
          </div>
        </div>
        <div className="p-5">
          <div
            className={`font-bold color-${
              location.pathname.split("/")[1]
            } text-lg mb-4 pb-3 border-b-2 lg:pr-16 `}
          >
            We Accept
          </div>
          <div>
            {" "}
            <img
              src={CardsIcons}
              className="cards-icons"
              alt="icons of cards"
            />
          </div>
        </div>
      </div>
      <div className="p-5 text-center border-t-2 border-gray-200 bg-white">
        ©2024 Boostik. All rights reserved |{" "}
        <Link to="/terms-and-conditions">Terms and conditions</Link>
        {" | "}
        <Link to="/privacy-policy">Privacy policy</Link>
      </div>
    </div>
  )
}

export default Footer
